<template>
  <div class="my__card">
    <div class="card__wrapper">
      <img :src="img" alt="">

      <div class="content">
        <div class="title text-center">
          <h3 class="">{{title}}</h3>

          <span><img src="@/assets/arrow.svg" alt="" class="arrow"></span>
        </div>

        <p>{{desk}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props:{
    img: String,
    title: String,
    desk: String
  }
}
</script>

<style scoped>
.my__card {
  margin: 10px 0;
  height: 140px;
  transition: 0.2s linear;
}

.card__wrapper {
  height: 100%;
  position: relative;
  color: var(--white-color);
  transition: 0.3s linear;
  background: linear-gradient(330.04deg, rgba(0, 0, 0, 0.5) 41.64%, rgba(0, 0, 0, 0) 93.23%);
  background-blend-mode: normal, multiply;
  border-radius: 34px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 34px;
  position: absolute;
  z-index: -1;
}

.title {
  height: 140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  transition: 0.3s linear;
}

h3 {
  text-align: center;
  display: flex;
  margin: auto;
  font-weight: 500;
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 28px;
  width: 75%;
}

p {
  text-align: center;
  display: flex;
  font-weight: 400;
  font-size: clamp(0.8rem, 2vw, 1.1rem);
  line-height: clamp(1rem, 2vw, 1.5rem);
  opacity: 0;
  transition: 50ms linear;
  margin: auto;
  width: 90%;
}

.my__card:hover {
  height:250px;
  transition: 0.3s linear;
}

.my__card:hover p {
  transition: 0.3s linear;
  opacity: 1;
}

.my__card:hover .arrow{
  transform: rotate(90deg);
  transition: 0.2s linear;
}

.my__card:hover .title{
  height: 130px !important;
  transition: 0.2s linear;
}

span{
  position: absolute;
  top: 45%;
  right: 50px;
  z-index: 999;
}

.arrow{
  width: clamp(0.3rem, 2vw, 1rem);
  height:clamp(0.3rem, 2vw, 1rem);
  transition: 0.3s linear;
}
</style>
