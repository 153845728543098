<template>
  <div class="mt-2">
    <input type="radio" :name="name" :id="id" :value="value" @input="inputText">
    <label class="label" :for="id">{{ placeholder }}</label>
  </div>
</template>

<script>
export default {
  name: "radio",
  data() {
    return {
      data: ''
    }
  },
  props: {
    name: String,
    placeholder: String,
    id: String,
    value: String,
  },
  methods: {
    inputText(e) {
      this.$emit('inputText', {
        inputValue: e.target.value
      })
    },
  },
}
</script>

<style scoped>
label {
  text-align: right;
  font-weight: 400;
  font-size: clamp(1.2rem, 2vw, 1.6rem);
  line-height: 24px;
  height: 1.2rem;
  color: #494950;
}

input[type="radio"] {
  height: clamp(1rem, 2vw, 1.2rem);
  width: clamp(1rem, 2vw, 1.2rem);
  margin-right: 0.5rem;
}

input {
  accent-color: #494950;

}
</style>
