<template>
  <footer class="w-100 p-5" id="contact">
    <div class="container row justify-content-center w-100 m-auto">
      <div class="col-lg-5 col-12">
        <p>
          Пошта <br>
          <a href="mailto:tetra.co.ua@gmail.com" target="_blank">tetra.co.ua@gmail.com</a>
        </p>
      </div>
      <div class="col-lg-3 col-12">
        <p>
          Instagram <br>
          <a href="https://www.instagram.com/tetra.co.ua/">@tetra.co.ua</a>
        </p>
      </div>
      <div class="col-lg-4 col-12">
        <div class="wrapper">
          <div class="wrapper__inner">
            <p style="margin-right: 9px;">
              Подзоров Андрій <br>
              <a href="tel:+380671513274">+380671513274</a>
              <img src="@/assets/viber.svg" alt="viber" class="viber">
              <img src="@/assets/telegram.svg" alt="telegram" class="telegram">
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container row justify-content-end align-items-center m-auto">
      <div class="col-lg-4 col-12">
        <div class="wrapper">
          <div class="wrapper__inner">
            <p style="margin-right: 7px;">
              Демченко Євген <br>
              <a href="tel:+380977991675">+380977991675</a>
              <img src="@/assets/viber.svg" alt="viber" class="viber">
              <img src="@/assets/telegram.svg" alt="telegram" class="telegram">
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-5">
      <p class="footer-text">
        Послуги надаються від Фізичної особи-підприємця Подзорова Андрія Миколайовича <br>
        м. Дніпро
      </p>
      <p class="footer-text">
        Всі права захищені ™
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped>
footer {
  height: 100%;
  min-height: 400px;
  background: var(--black-color);
  color: var(--white-color);
  font-size: 20px;
}

a {
  color: var(--white-color);
  text-decoration: none;
}

.viber {
  margin-left: 25px;
}

.telegram {
  margin-left: 5px;
}

.footer-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.5);
}

.wrapper{
}

.wrapper__inner {
  display: flex;
  justify-content: end;
}

</style>
