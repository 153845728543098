<template>
  <Nav style="z-index: 9999" />
  <div class="container-fluid m-auto">
    <div class="mt-3">
      <carousel class="mb-5 mt-4 carousel" />

     <div class="container mt-5 mb-5 m-auto">


       <div class="row justify-content-between" id="cards">
         <Cards  />
       </div>

       <request-form class="mt-5" @send="sendRequest" />

       <div class="mt-5 w-100">
         <Services id="about" />
       </div>

       <Text />

     </div>

      <Creterias class="mt-5" />

      <text2 class="my-5" />

    </div>
  </div>
  <my-footer />
</template>

<script>
import carousel from '../components/Carousel/Сarousel.vue'
import Cards from "../components/Cards";
import RequestForm from "../components/requestForm";
import Services from "../components/Services";
import Text from "../components/text";
import text2 from "../components/text2";
import Creterias from "../components/Creterias";
import myFooter from "../components/myFooter";
import {Toasts} from "../toasts";
import axios from 'axios'
import Nav from "../components/nav";

export default {
  name: 'Home',
  components: {
    Nav,
    Creterias,
    text2,
    Cards,
    RequestForm,
    carousel,
    Services,
    Text,
    myFooter
  },
  methods:{
    async sendRequest(e){
      try{
        await axios.post('https://api.tetra.co.ua/',{
          category: e.category,
          type: e.type,
          company: e.company,
          step1: e.step1,
          step2: e.step2,
          phone: e.phone,
          name: e.name,
          weight: e.weight
        })
        Toasts.createSuccessToast()
      }catch (err){
        Toasts.createErrorToast()
      }
    }
  }
}
</script>

<style scoped>

</style>
