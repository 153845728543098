<template>
  <div class="container m-auto row align-items-center justify-content-center mb-5">
    <div class="col">
      <div class="my__card">
        <img src="@/assets/icon6.svg" alt="">
        <p>
          Пошук найкращих та вигідних умов перевезення
        </p>
      </div>
    </div>
    <div class="col">
      <div class="my__card">
        <img src="@/assets/icon7.svg" alt="">
        <p>
          Широка
          географія перевезень
        </p>
      </div>
    </div>
    <div class="col">
      <div class="my__card">
        <img src="@/assets/icon8.svg" alt="">
        <p>
          Залучення
          різних видів транспорту
        </p>
      </div>
    </div>
    <div class="col">
      <div class="my__card">
        <img src="@/assets/icon9.svg" alt="">
        <p>
          Індивідуальний підхід до
          кожного клієнта
        </p>
      </div>
    </div>
    <div class="col">
      <div class="my__card">
        <img src="@/assets/icon11.svg" alt="">
        <p>
          Моніторинг перевезень та інформування клієнта в режимі 24/7
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Creterias"
}
</script>

<style scoped>
img {
  width: 2.5rem;
  height: 2.5rem;
}

.my__card {
  margin: 20px auto;
  width: 220px;
  min-height: 240px;
  height: 100%;
  padding: 20px 30px;
  box-shadow: 0px 4px 23px rgba(59, 51, 74, 0.15);
  border-radius: 58px;
}

p {
  margin-top: 30px;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 24px;
}
</style>
