<template>
  <Flicking
      :plugins="plugins"
      :options="{
    moveType: 'strict',
    panelsPerView: 1.2,
    align: 'prev',
    resizeOnContentsReady: true,
    circular: true,
  }"
      ref="flicking">
    <slide image="./bg1.jpg" title="Перевезення вантажів автомобільним транспортом" key="1" />
    <slide image="./bg2.jpg" title="Перевезення вантажів залізничним транспортом" key="2" />
    <slide image="./bg3.jpg" title="Мультимодальні перевезення вантажів" key="3" />
  </Flicking>
</template>

<script>
import Flicking from "@egjs/vue3-flicking";
import {AutoPlay} from "@egjs/flicking-plugins";
import "@egjs/vue3-flicking/dist/flicking-inline.css";
import Slide from "./Slide";

export default {
  name: "Сarousel",
  components: {
    Flicking: Flicking,
    Slide
  },
  data() {
    return {
      plugins: [
        new AutoPlay({duration: 3000, direction: "NEXT", stopOnHover: false})
      ],
    }
  },
  props: {
    data: Object
  },
  methods: {
    onMoveEnd() {
      this.$refs.flicking.next();
    }
  }
}
</script>

<style scoped>

img {
  pointer-events: none;
}
</style>
