<template>
  <div class="panel">
    <div class="w-100">
      <img src="@/assets/fullLogo.svg" alt="" class="fullLogo">
    </div>
    <div class="w-100">
      <h1 class="slide__title">{{title}}</h1>

      <button class="button" v-scroll-to="'#form'">Залишити заявку</button>
    </div>
    <img :src="image" alt="" class="bg">
  </div>
</template>

<script>
export default {
  name: "Slide",
  props:{
    image: String,
    title: String,
    withButton: Boolean
  },
  methods:{
  }
}
</script>

<style scoped>
img {
  pointer-events: none;
}
.panel {
  margin: 0 1%;
  position: relative;
  width: 100%;
  height: 80vh;
}
.bg {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 53px;
  background-blend-mode: normal, multiply;
  z-index: -1;
}

.bg:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(79deg, rgba(3, 3, 3, 0.5) 0.21%, rgba(3, 3, 3, 0) 98.78%);
}

.slide__title {
  font-size: clamp(0.8rem, 7vw, 4.3rem);
  color: var(--white-color);
  position: absolute;
  top: calc(100% / 4);
  width: calc(100% / 2);
  max-width: 500px;
  min-width: 250px;
  left: 50px;
}

.button {
  position: absolute;
  bottom: 50px;
  width: 60%;
  max-width: 400px;
  height: 55px;
  left: 50px;
  border: none;
  background: rgba(255, 255, 255, 0.8);
  color: var(--black-color);
  font-size: clamp(1rem, 4vw, 1.8rem);
  border-radius: 37.5px;
}
.fullLogo {
  position: absolute;
  top: 33px;
  width: calc(100% / 3);
  max-width: 400px;
  min-width: 250px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
