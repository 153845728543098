<template>
  <div class="form" id="form">
    <div class="row align-items-center justify-content-center m-auto pb-4">
      <div class="col-12 text-center mt-4">
        <h1>Залишити заявку</h1>
      </div>

      <div class="col-12 mt-4 row justify-content-center text-center">
        <div class="col-lg-5 col-12">
          <div class="mt-2">
            <input type="radio" name="name" id="1" value="я замовник" v-model="category">
            <label class="label1" for="1">я замовник</label>
          </div>
        </div>

        <div class="col-lg-5 col-12">
          <div class="mt-2">
            <input type="radio" name="name" id="2" value="я власник рухомого складу" v-model="category">
            <label class="label1" for="2">я власник рухомого складу</label>
          </div>
        </div>
      </div>

      <div class="col-12 row mt-4" v-if="category === 'я замовник'">
        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Тип перевезення" :value="type" @inputText="(value) =>this.type = value.inputValue" />
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Найменування замовника" :value="company" @inputText="(value) =>this.company = value.inputValue" />
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Пункт завантаження" :value="step1" @inputText="(value) =>this.step1 = value.inputValue" />
        </div>

        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Пункт вивантаження" :value="step2" @inputText="(value) =>this.step2 = value.inputValue" />
        </div>
      </div>


      <div class="col-12 row mt-4" v-else>
        <div class="col-lg-4 col-md-6 col-12">
          <text-input placeholder="Тип перевезення" :value="type" @inputText="(value) =>this.type = value.inputValue" />
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <text-input placeholder="Найменування перевізника" :value="company" @inputText="(value) =>this.company = value.inputValue" />
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <text-input placeholder="Інформація про рухомий склад" :value="weight" @inputText="(value) =>this.weight = value.inputValue" />
        </div>
      </div>

      <div class="col-12 row mt-4" v-if="category === 'я замовник'">
        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Ваш номер телефону" :value="phone" @inputText="(value) =>this.phone = value.inputValue" />
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Найменування вантажу" :value="name" @inputText="(value) =>this.name = value.inputValue" />
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <text-input placeholder="Маса, т" :value="weight" @inputText="(value) =>this.weight = value.inputValue" />
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <send-button text="Залишити заявку" @click="send" />
        </div>
      </div>

      <div class="col-12 row mt-4" v-else>
        <div class="col-lg-4 col-md-6 col-12">
          <text-input placeholder="Ваш номер телефону" :value="phone" @inputText="(value) =>this.phone = value.inputValue" />
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <text-input placeholder="Бажана область роботи" :value="name" @inputText="(value) =>this.name = value.inputValue" />
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <send-button text="Залишити заявку" @click="send" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textInput from "./inputs/textInput";
import sendButton from "./buttons/sendButton";
import radio from "./inputs/radio"

export default {
  name: "requestForm",
  components: {textInput, sendButton, radio},
  data() {
    return {
      category: 'я замовник',
      type: '',
      company: '',
      step1: '',
      step2: '',
      phone: '',
      name: '',
      weight: '',
      info: '',
    }
  },
  methods:{
    send(){
      this.$emit('send', {
        category: this.category,
        type: this.type,
        company: this.company,
        step1: this.step1,
        step2: this.step2,
        phone: this.phone,
        name: this.name,
        weight: this.weight,
        info: this.info,
      })
    },
  }
}
</script>

<style scoped>
.form {
  width: 100%;
  height: 100%;
  min-height: 400px;
  box-shadow: 0px 4px 23px rgba(59, 51, 74, 0.15);
  border-radius: 58px;
}

h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.label1 {
  text-align: right;
  font-weight: 400;
  font-size: clamp(1.2rem, 2vw, 1.6rem);
  line-height: 24px;
  height: 1.2rem;
  color: #494950;
}

input[type="radio"] {
  height: clamp(1rem, 2vw, 1.2rem);
  width: clamp(1rem, 2vw, 1.2rem);
  margin-right: 0.5rem;
}

input[type="radio"] {
  accent-color: #494950;

}
</style>
