<template>
  <div class="col-9 mt-5 m-auto text">
    <p>
      Територія транспорту – це команда досвідчених фахівців, що працюють на ринку вантажоперевезень понад 10 років.
    </p>

    <br>

    <p>
      Ми організовуємо перевезення як збірних і консолідованих відправок, так і повнокомплектних вантажів. Відмінне знання маршрутів та підбір транспортного засобу, що максимально відповідає типу і кількості товару, дозволяють нам зробити кожну поїздку максимально ефективною.
    </p>
  </div>
</template>

<script>
export default {
  name: "text"
}
</script>

<style scoped>
.text{
  font-weight: 500;
  font-size: clamp(1rem, 2vw, 1.3rem);
  line-height: 29px;
}
</style>
