<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" id="top">
    <div class="container bg">
      <a class="navbar-brand"><img src="@/assets/logo.svg" alt=""></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav w-100 justify-content-between">
          <li class="nav-item">
            <a v-scroll-to="'#cards'" class="nav-link">Види перевезень</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="'#form'" class="nav-link">Залишити заявку</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="'#about'" class="nav-link">Про нас</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" v-scroll-to="'#contact'">Контакти</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "nav"
}
</script>

<style scoped>
.bg{
  background: #212529;
}
</style>
