<template>
  <div class="col-9 my-5 m-auto text text-center">
    <p>
      Довіряючи нам свій вантаж, ви можете бути впевненими в його своєчасній та безпечній доставці! <br>
      Зв'яжіться з нами будь-яким зручним для Вас способом, щоб почати довгу та плідну співпрацю!
    </p>
  </div>
</template>

<script>
export default {
  name: "text"
}
</script>

<style scoped>
.text{
  font-weight: 500;
  font-size: clamp(1rem, 2vw, 1.3rem);
  line-height: 29px;
  border: 2px solid #494950;
  border-radius: 61.5px;
  padding: 20px;
  font-style: normal;
  color: #1D1D1D;
}

p{
  margin: 0 !important;
}
</style>
