<template>
  <div class="my__card row align-items-center justify-content-center">
    <img src="@/assets/bg4.jpg" alt="" class="bg">

    <div class="title text-center col-12">
      <h2 class="my-3">
        Компанія надає повний комплекс послуг з перевезення вантажів залізничним та автомобільним транспортом по Україні та у міжнародному сполученні, включаючи:
      </h2>
    </div>

    <div class="row m-auto justify-content-center">

      <div class="col mt-4">
        <img src="@/assets/icon1.svg" alt="">
        <p>
          пошук та підбір <br> транспорту
        </p>
      </div>

      <div class="col mt-4">
        <img src="@/assets/icon2.svg" alt="">
        <p>
          побудова та розрахунок оптимального маршруту
        </p>
      </div>

      <div class="col mt-4">
        <img src="@/assets/icon3.svg" alt="">
        <p>
          документальний супровід перевезення
        </p>
      </div>

      <div class="col mt-4">
        <img src="@/assets/icon4.svg" alt="">
        <p>
          моніторинг перебігу перевезення
        </p>
      </div>

      <div class="col mt-4">
        <img src="@/assets/icon5.svg" alt="">
        <p>
          митне оформлення <br> вантажів
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "Services"
}
</script>

<style scoped>
.my__card{
  height: 100%;
  min-height: 400px;
  position: relative;
  border-radius: 58px;
  text-align: center;
  overflow: hidden;
}

.bg{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  border-radius: 58px;
  padding: 0;
  margin: 0;
}

title{
  width: 100%;
  min-height: 400px;
  height: 100%;
  text-align: center;
  margin: auto;
  display: flex;
}

h2{
  width: 90%;
  height: 100%;
  font-weight: 500;
  font-size: clamp(1.2rem, 2vw, 1.7rem);
  line-height: 29px;
  margin: auto;
}

p{
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  margin-top: 20px;
}
</style>
