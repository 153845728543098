<template>
  <button>{{text}}</button>
</template>

<script>
export default {
  name: "sendButton",
  props: {
    text: String
  }
}
</script>

<style scoped>
button{
  background: #494950;
  border-radius: 50px;
  height: 40px;
  width: 100%;
  margin-top: 25px;
  border: 1px solid #000000;
  color: var(--white-color);
  transition: 0.5s ease;
}

button:hover{
  background: var(--black-color);
  transition: 0.5s ease;
}
</style>
