<template>
  <input type="text" :placeholder="placeholder" :value="value" @input="inputText" >
</template>

<script>
export default {
  name: "input",
  props:{
    placeholder: String,
    value: String
  },
  methods:{
    inputText(e){
      this.$emit('inputText', {
        inputValue: e.target.value
      })
    },
  }
}
</script>

<style scoped>

input{
  border: solid 1px #494950;
  border-radius: 50px;
  padding-left: 28px;
  height: 40px;
  width: 100%;
  outline: none;
  margin-top: 25px;
}

input::placeholder{
  color: rgba(73, 73, 80, 0.5);
}

</style>
