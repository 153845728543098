<template>
  <card class="col-12 col-lg-6" img="https://tetra.co.ua/card1.jpg" title="Залізничні перевезення (всі види рухомого складу)" desk="Перевезення вантажів залізницями України та країн Європи в універсальних та спеціалізованих вагонах з повним документальним супроводом." />
  <card class="col-12 col-lg-6" img="https://tetra.co.ua/card2.jpg" title="Перевезення тарно-штучних вантажів (від 1 т до 25 т, збірні вантажі, ADR)" desk="Перевезення широкої номенклатури вантажів, в т.ч. які потребують дотримання температурного режиму або особливих умов перевезень, в тентованих вантажівках та рефрижераторах." />
  <card class="col-12 col-lg-6" img="https://tetra.co.ua/card3.jpg" title="Перевезення зернових та насипних вантажів" desk="Перевезення зернових культур та інших сипучих вантажів (шлак, чорнозем, добрива, щепа) в зерновозах, щеповозах та самоскидах." />
  <card class="col-12 col-lg-6" img="https://tetra.co.ua/card4.jpg" title="Перевезення негабаритних вантажів" desk="Перевезення вантажів, габарити та вага яких перевищують стандартні (сільськогосподарське та промислове обладнання, будівельні матеріали), на спеціалізованих тралах та платформах." />
  <card class="col-12 col-lg-6" img="https://tetra.co.ua/card5.jpg" title="Перевезення контейнерів" desk="Перевезення контейнерів (20, 30, 40, 45 футові) спеціальними автомобільними напівпричепами-контейнеровозами." />
  <card class="col-12 col-lg-6" img="https://tetra.co.ua/card6.jpg" title="Перевезення наливних вантажів (в т.ч. ADR, посекційний налив)" desk="Перевезення, рідких харчових та хімічних вантажів, нафтопродуктів та скраплених газів в автомобільних цистернах." />
</template>

<script>
import Card from "./Card";
export default {
  name: "Cards",
  components:{
    Card
  }
}
</script>

<style scoped>

</style>
